import React, {useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {FormLabel, FormGroup, FormControlLabel} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import './pmtform.css'
import { ButtonA } from '../Components/Button.js'
import { TextField, Select, MenuItem, Checkbox, Divider, Grid, Snackbar } from '@material-ui/core';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

  
const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		boxShadow: 'none',
		backgroundColor: 'transparent'
	},
	title: {
		flex: 1,
		fontWeight: 'normal',
		color: 'grey',
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(3)
	},
	text: {
		flex: 1,
		fontWeight: 'normal',
		color: '#333',
		marginBottom: theme.spacing(2)
	},
	close: {
	    color: 'white',
	    backgroundColor: 'darkorange',
	    position: 'fixed',
	    top: '20px',
	    right: '20px',
	    '&:hover': {
	      color: 'white',
	      backgroundColor: 'darkorange',
	  	}
    },
	formControl: {
	},
	formControlCheckox: {
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(props.openform);
	const [message, setmessage] = useState(false);
	const [formstatus, setformstatus] = useState('error');
	const [logtext , setlogtext] = useState('');
	const [dur, setdur] = useState(1);
	const [mode, setmode] = useState(1);
	const [country, setcountry] = useState(1);
	const [hindi, sethindi] = useState(true);
	const [english, setenglish] = useState(false);
	const [gujarati, setgujarati] = useState(false);
	const [marathi, setmarathi] = useState(false);
	const [price, setprice] = useState(180);
	const [fname, setfname] = useState('');
	const [lname, setlname] = useState('');
	const [address, setaddress] = useState('');
	const [city, setcity] = useState('');
	const [state, setstate] = useState('');
	const [nation, setnation] = useState('');
	const [pin, setpin] = useState('');
	const [mob, setmob] = useState('');
	const [email, setemail] = useState('');
	const [emailerr, setemailerr] = useState('');
	
	const handleDurationChange = (event) => {
		const d = event.target.value;
		if(mode === 2) {
			if(d===1)
				setprice(150);
			else if(d===2)
				setprice(260);
			else setprice(600);
		}
		else {
			if(d===1)
				setprice(180);
			else if(d===2)
				setprice(320);
			else setprice(750);
		}
		setdur(d);
		sethindi(true);
		setenglish(false);
		setgujarati(false);
		setmarathi(false);
	};

	const handleModeChange = (event) => {
		const d = event.target.value;
		if(d===2)
			setprice(150);
		else
			setprice(180);
		setmode(d);
		sethindi(true);
		setenglish(false);
		setgujarati(false);
		setmarathi(false);
		setdur(1);
		setcountry(1);
	};

	const handleCountryChange = (event) => {
		const d = event.target.value;
		if(d===1)
			setprice(150);
		else if(d===2)
			setprice(20);
		setcountry(d);
		sethindi(true);
		setenglish(false);
		setgujarati(false);
		setmarathi(false);
		setdur(1);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if(name==='fname')
		  setfname(value);
		if(name==='lname')
		  setlname(value);
		if(name==='address')
		  setaddress(value);
		if(name==='city')
		  setcity(value);
		if(name==='state')
		  setstate(value);
		if(name==='nation')
		  setnation(value);
		if(name==='pin')
		  setpin(value);
		if(name==='mob')
		  setmob(value);
		if(name==='email') {
		  	setemail(value);
			// eslint-disable-next-line no-useless-escape
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(value) === false) {
				setemailerr('Email is Not Correct');
				return;
			}
			else {
				setemailerr('');
			}
		}
	};

	const sendEmail = (mail) => {
		emailjs.send("service_aaoatkl","template_jhlx55j",{
			to_email: mail,
		}, "user_sY1m8Ki5Oafoc32NNK9TG");

		emailjs.send("service_6tpaae9","template_ehavusr",{
			form_link: "https://lpdf.in/850274/Admin/PrabhuMeinTera",
			form_name: "Prabhu Mein Tera",
			to_email: "poonam.lpdf2@gmail.com",
		}, "user_WO7pjjJ2kpBHGbUJekjMm");
	}
	
	const handleFormSubmit = async (e) => {
		setmessage(!message)
		const formData = new FormData()
		if (fname && address && city && state && nation && pin && mob && email && (hindi || english || marathi || gujarati)) {
			let x=hindi?'Hindi ':''
			let y=english?'English ':''
			let z=marathi?'Marathi ':''
			let t=gujarati?'Gujarati ':''
			let m=mode===1?'Print Magazine':'E-Magazine'
			formData.append("name", fname+' '+lname);
			formData.append("email", email);
			formData.append("address", address+', '+city+', '+state+', '+nation+', '+pin);
			formData.append("phone", mob);
			formData.append("mode", m);
			formData.append("duration", dur+' Year');
			formData.append("language", x+y+z+t);
			formData.append("payment", '');
			setmessage(true)
			setformstatus('info');
			setlogtext('Submitting');
			await axios({
		      url: 'https://lpdf.in/lpdf_backend/home/insert_into_pmt_form.php',
		      method: 'post',
		      data: formData,
		      responseType: 'json'
		    })
		    .then(() => {
				sendEmail(email);
				setmessage(true);
				setformstatus('success');
				setlogtext('Details Submitted Successfully');
				setdur(1);
				setmode(2);
				setcountry(1);
				sethindi(true);
				setenglish(false);
				setgujarati(false);
				setmarathi(false);
				setprice(150);
				setfname('');
				setlname('');
				setaddress('');
				setcity('');
				setstate('');
				setnation('');
				setpin('');
				setmob('');
				setemail('');
		    })
		    .catch(err => {
				console.log('err', err)
				setmessage(true)
				setformstatus('error')
				setlogtext('Something went wrong')
			})
		}
		else {
			setmessage(true)
			setformstatus('error');
			setlogtext('Please fill all details');
		}
	};

	const handleLanguageChange = (event) => {
		let l = event.target.name;
		if(l === "hindi") {
			sethindi(!hindi)
			setenglish(false)
			setgujarati(false)
			setmarathi(false)
		}
		else if(l === "english") {
			setenglish(!english)
			sethindi(false)
			setgujarati(false)
			setmarathi(false)
		}
		else if(l === "gujarati") {
			setgujarati(!gujarati)
			sethindi(false)
			setenglish(false)
			setmarathi(false)
		}
		else if(l === "marathi") {
			setmarathi(!marathi)
			sethindi(false)
			setenglish(false)
			setgujarati(false)
		};
	}

	return (
		<div>
			<ButtonA buttonto={props.buttontext} onClick={handleClickOpen} />
				<Dialog className={classes.dailog} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<div className="form-header">
					<AppBar className={classes.appBar}>
						<Toolbar>
							<Typography variant="h6" className={classes.title}>
							</Typography>
							<Link to="/PrabhuMeinTera"><IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
								<CloseIcon />
							</IconButton></Link>
						</Toolbar>
					</AppBar>
					<svg  className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                    
						<path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
						<path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
						<path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
					</svg>
				</div>
				<div className="form-wrapper">
					<h1 className="t1" >Prabhu Mein Tera</h1>
					<h6 className="t2 my-3" >Subscription form</h6>
					<Grid container className="my-5" alignItems="center">
						<Grid item xs={12} md={4}>
							<div className="mb-5 mb-lg-0">
								<img className="aa" src={require("../images/pmt/covers_sm/2020_4.jpg")} alt="" width="100%" height="auto"/>
							</div>
						</Grid>
						<Grid container xs={12} md={8} spacing={3}>
							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" className={classes.formControl} fullWidth>
									<InputLabel id="demo-simple-select-outlined-label">Duration</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={dur}
										onChange={handleDurationChange}
										label="Duration"
									>
										<MenuItem value={1}>1 Year</MenuItem>
										{country===1?<MenuItem value={2}>2 Year</MenuItem>:undefined}
										{country===1?<MenuItem value={5}>5 Year</MenuItem>:undefined}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" className={classes.formControl} fullWidth>
									<InputLabel id="demo-simple-select-outlined-label">Print/E-magazine</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={mode}
										onChange={handleModeChange}
										label="Print/E-magazine"
									>
										<MenuItem value={1}>Print Magazine</MenuItem>
										<MenuItem value={2}>E-Magazine</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl component="fieldset" className={classes.formControlCheckox} fullWidth>
									<FormLabel component="legend">Select Language</FormLabel>
										<FormGroup row>
											<FormControlLabel
												control={<Checkbox checked={hindi} onChange={handleLanguageChange} name="hindi" />}
												label="Hindi"
											/>
											{mode===2?
											<FormControlLabel
												control={<Checkbox checked={english} onChange={handleLanguageChange} name="english" />}
												label="English"
											/>:undefined}
											<FormControlLabel
												control={<Checkbox checked={gujarati} onChange={handleLanguageChange} name="gujarati" />}
												label="Gujarati"
											/>
											<FormControlLabel
												control={<Checkbox checked={marathi} onChange={handleLanguageChange} name="marathi" />}
												label="Marathi"
											/>
										</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl variant="outlined" className={classes.formControl} fullWidth>
									<InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
									<Select
										labelId="demo-simple-select-outlined-label"
										id="demo-simple-select-outlined"
										value={country}
										onChange={handleCountryChange}
										label="Country"
									>
										<MenuItem value={1}>India</MenuItem>
										{mode===2?<MenuItem value={2}>Other Countries</MenuItem>:undefined}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<FormControl component="fieldset" className={classes.formControl} fullWidth>
									<FormLabel component="legend">Subscription Charges</FormLabel>
									<Typography variant="p">
										{country===1?<b>{'₹' + price}</b>:<b>{price+'US$'}</b>}
									</Typography>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Divider />
					<div className="my-5">
						<Typography variant="h6" className={classes.title}>
							Personal Details
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="fname"
									name="fname"
									variant="outlined"
									required
									fullWidth
									id="fname"
									label="First Name"
									value={fname}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="lname"
									label="Last Name"
									name="lname"
									autoComplete="lname"
									value={lname}
                					onChange={handleInputChange}
								/>
							</Grid>
						</Grid>

						<Typography variant="h6" className={classes.title}>
							Address Details
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="address"
									label="Address"
									name="address"
									autoComplete="address"
									value={address}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="city"
									name="city"
									variant="outlined"
									required
									fullWidth
									id="city"
									label="City"
									value={city}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="state"
									label="State"
									name="state"
									autoComplete="state"
									value={state}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="nation"
									label="Country"
									name="nation"
									autoComplete="nation"
									value={nation}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="pin"
									label="Pin Code"
									name="pin"
									autoComplete="pin"
									value={pin}
                					onChange={handleInputChange}
								/>
							</Grid>
						</Grid>

						<Typography variant="h6" className={classes.title}>
							Contact Details
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="mob"
									name="mob"
									variant="outlined"
									required
									fullWidth
									id="mob"
									label="mob"
									value={mob}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="email"
									name="email"
									autoComplete="email"
									value={email}
                					onChange={handleInputChange}
								/>
								<p style={{color: 'orangered', fontSize: '0.8rem', marginLeft: '8px'}}>{emailerr}</p>
							</Grid>
							<Grid item xs={12} className="mt-4 text-right pmt-form-buttons">
								<Link to="/PrabhuMeinTera"><ButtonA buttonto="Back" onClick={handleClose}/></Link>
								<div className="ml-3 pmt-submit-btn"><ButtonA buttonto="Submit Details" onClick={handleFormSubmit}/></div>
							</Grid>
							<Snackbar open={message} autoHideDuration={6000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
								<Alert onClose={() => setmessage(false)} severity={formstatus}>
									{logtext}
								</Alert>
							</Snackbar>
						</Grid>
					</div>
					<Divider/>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6" className={classes.title}>
								Payment Options
							</Typography>
						</Grid>
						<Grid item xs={12} className="mb-3">
							<Typography variant="p" className={classes.text}>
								You have to transfer {country===1?<b>{'₹' + price}</b>:<b>{price+'US$'}</b>} online to following account.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="p" className={classes.text}>
								LAKSH PRERNA DIVINE FOUNDATION 
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="p" className={classes.text}>
								Axis Bank Ltd.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="p" className={classes.text}>
								AC no. <b>911010024568319</b> chand kheda branch
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="p" className={classes.text}>
								ifsc code <b>UTIB0000751</b>
							</Typography>
						</Grid>
						<Grid item xs={12} className="mt-3">
							<Typography variant="p" className={classes.text}>
								<b>We will reach out to you within 24 hours of payment.</b>
							</Typography>
						</Grid>
					</Grid>
				</div>
			</Dialog>
		</div>
	);
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import '../fonts.css'
import './ekadashiblog.css'

import e1 from '../images/ekadashi/26Ekadashis/small/e1.jpg'
import e2 from '../images/ekadashi/26Ekadashis/small/e2.jpg'
import e3 from '../images/ekadashi/26Ekadashis/small/e3.jpg'
import e4 from '../images/ekadashi/26Ekadashis/small/e4.jpg'
import e5 from '../images/ekadashi/26Ekadashis/small/e5.jpg'
import e6 from '../images/ekadashi/26Ekadashis/small/e6.jpg'
import e7 from '../images/ekadashi/26Ekadashis/small/e7.jpg'
import e8 from '../images/ekadashi/26Ekadashis/small/e8.jpg'
import e9 from '../images/ekadashi/26Ekadashis/small/e9.jpg'
import e10 from '../images/ekadashi/26Ekadashis/small/e10.jpg'
import e11 from '../images/ekadashi/26Ekadashis/small/e11.jpg'
import e12 from '../images/ekadashi/26Ekadashis/small/e12.jpg'
import e13 from '../images/ekadashi/26Ekadashis/small/e13.jpg'
import e14 from '../images/ekadashi/26Ekadashis/small/e14.jpg'
import e15 from '../images/ekadashi/26Ekadashis/small/e15.jpg'
import e16 from '../images/ekadashi/26Ekadashis/small/e16.jpg'
import e17 from '../images/ekadashi/26Ekadashis/small/e17.jpg'
import e18 from '../images/ekadashi/26Ekadashis/small/e18.jpg'
import e19 from '../images/ekadashi/26Ekadashis/small/e19.jpg'
import e20 from '../images/ekadashi/26Ekadashis/small/e20.jpg'
import e21 from '../images/ekadashi/26Ekadashis/small/e21.jpg'
import e22 from '../images/ekadashi/26Ekadashis/small/e22.jpg'
import e23 from '../images/ekadashi/26Ekadashis/small/e23.jpg'
import e24 from '../images/ekadashi/26Ekadashis/small/e24.jpg'
import e25 from '../images/ekadashi/26Ekadashis/small/e25.jpg'
import e26 from '../images/ekadashi/26Ekadashis/small/e26.jpg'

import t1 from '../Blogs/e1.js'
import t2 from '../Blogs/e2.js'
import t3 from '../Blogs/e3.js'
import t4 from '../Blogs/e4.js'
import t5 from '../Blogs/e5.js'
import t6 from '../Blogs/e6.js'
import t7 from '../Blogs/e7.js'
import t8 from '../Blogs/e8.js'
import t9 from '../Blogs/e9.js'
import t10 from '../Blogs/e10.js'
import t11 from '../Blogs/e11.js'
import t12 from '../Blogs/e12.js'
import t13 from '../Blogs/e13.js'
import t14 from '../Blogs/e14.js'
import t15 from '../Blogs/e15.js'
import t16 from '../Blogs/e16.js'
import t17 from '../Blogs/e17.js'
import t18 from '../Blogs/e18.js'
import t19 from '../Blogs/e19.js'
import t20 from '../Blogs/e20.js'
import t21 from '../Blogs/e21.js'
import t22 from '../Blogs/e22.js'
import t23 from '../Blogs/e23.js'
import t24 from '../Blogs/e24.js'
import t25 from '../Blogs/e25.js'
import t26 from '../Blogs/e26.js'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  blogHeader: {
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat',
    backgroundPosition: 'left top'
  },
  title: {
    flex: 1,
    fontWeight: 'normal',
    color: 'grey',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3)
  },
  text: {
    flex: 1,
    fontWeight: 'normal',
    color: '#333',
    marginBottom: theme.spacing(2)
  },
  close: {
    color: 'white',
    backgroundColor: 'orangered',
    position: 'fixed',
    top: '20px',
    right: '20px',
    '&:hover': {
      color: 'white',
      backgroundColor: 'orangered',
    },
  },
  blogBody: {
    maxWidth: '800px',
    marginTop: '50px',
    marginBottom: '50px'
  }
}));

const data = [
  ["Papmochani Ekadashi", "Chaitra-Krishna Ekadashi (March-April)", e1, t1,"https://www.youtube.com/embed/ekdHjeuo0ak"],
  ["Kamada Ekadashi", "Chaitra-Shukla Ekadashi (March-April)", e2, t2,"https://www.youtube.com/embed/zwtIRU1UUNk"],
  ["Varuthini Ekadashi", "Vaisakha-Krishna Ekadashi (April-May)", e3, t3,"https://www.youtube.com/embed/4loZ1tbph1o"],
  ["Mohini Ekadashi", "Vaisakha-Shukla Ekadashi (April-May)", e4, t4,"https://www.youtube.com/embed/U2Y0t_Zyc8E"],
  ["Apara Ekadashi", "Jyeshtha-Krishna Ekadashi (May-June)", e5, t5,"https://www.youtube.com/embed/RCaMnS4ALII"],
  ["Nirjala Ekadashi", "Jyeshtha-Shukla Ekadashi (May-June)", e6, t6,"https://www.youtube.com/embed/_4kAPdyX6YM"],
  ["Yogini Ekadashi", "Ashadha-Krishna Ekadashi (June-July)", e7, t7,"https://www.youtube.com/embed/xzMZ3lEhPfw"],
  ["Devshayani (Padma) Ekadashi", "Ashadha-Shukla Ekadashi (June-July)", e8, t8,"https://www.youtube.com/embed/8SrydXFWH7k"],
  ["Kamika Ekadashi", "Shravana-Krishna Ekadashi (July-August)", e9, t9,"https://www.youtube.com/embed/LqXjoAqhPzU"],
  ["Putrada Ekadashi", "Shravana-Shukla Ekadashi (July-August)", e10, t10,"https://www.youtube.com/embed/3Hwxk0CDyQ0"],

  ["Aja Ekadashi", "Bhadrapada-Krishna Ekadashi (August-September)", e11, t11,"https://www.youtube.com/embed/EDeFfHb1yzA"],
  ["Parivartini (Padma) Ekadashi", "Bhadrapada-Shukla Ekadashi (August-September)", e12, t12,"https://www.youtube.com/embed/nc_244mIoKY"],
  ["Indira Ekadashi", "Ashwin-Krishna Ekadashi (September-October)", e13, t13,"https://www.youtube.com/embed/0dIFBYnsKjY"],
  ["Papankusha Ekadashi", "Ashwin-Shukla Ekadashi (September-October)", e14, t14,"https://www.youtube.com/embed/4eJz6czZ1u4"],
  ["Rama Ekadashi", "Kartik-Krishna Ekadashi (October-November)", e15, t15,"https://www.youtube.com/embed/RYaT1K5Vf08"],
  ["Haribodhini (Devothaani) Ekadashi", "Kartik-Shukla Ekadashi (October-November)", e16, t16,"https://www.youtube.com/embed/H7uNVXhZO3U"],
  ["Utpanna Ekadashi", "Margasirsha-Krishna Ekadashi (November-December)", e17, t17,"https://www.youtube.com/embed/n9VOePdf_34"],
  ["Mokshada Ekadashi", "Margasirsha-Shukla Ekadashi (November-December)", e18, t18,"https://www.youtube.com/embed/PnQIuO8bFJ4"],
  ["Saphala Ekadashi", "Pausha-Krishna Ekadashi (December-January)", e19, t19,"https://www.youtube.com/embed/KFaPLnIkFY8"],
  ["Vaikuntha (Putrada) Ekadashi", "Paush-Shukla Ekadashi (December-January)", e20, t20,"https://www.youtube.com/embed/56A1m3R8p1k"],

  ["Shattila Ekadashi", "Magha-Krishna Ekadashi (January-February)", e21, t21,"https://www.youtube.com/embed/b7auS8GOHP0"],
  ["Jaya Ekadashi", "Magha-Shukla Ekadashi (January-February)", e22, t22,"https://www.youtube.com/embed/jGu37avVMKk"],
  ["Vijaya Ekadashi", "Phalguna-Krishna Ekadashi (February-March)", e23, t23,"https://www.youtube.com/embed/KG3rrUMhwqQ"],
  ["Amalaki Ekadashi", "Phalguna-Shukla Ekadashi (February-March)", e24, t24,"https://www.youtube.com/embed/LtXV1a767aQ"],
  ["Parama Ekadashi", "Adik Maas-Krishna Ekadashi", e25, t25,"https://www.youtube.com/embed/bMSj9cjNR1I"],
  ["Padmini Ekadashi", "Adik Maas-Shukla Ekadashi", e26, t26,"https://www.youtube.com/embed/mLa6M_fDJpM"]
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EkadashiBlog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [title, settitle] = React.useState(props.title);
  const [subtitle, setsubtitle] = React.useState(props.subtitle);
  const [video, setvideo] = React.useState(props.video);
  const [file , setfile] = React.useState(props.file);
  const [bg , setbg] = React.useState(props.banner);

  const handleClickOpen = () => {
    setOpen(true);
    settitle(props.title);
    setsubtitle(props.subtitle);
    setfile(props.file);
    setbg(props.banner);
    setvideo(props.video);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var dialogContent = undefined;

  const setBlog = (title, subtitle, video, file, bg) => {
    settitle(title);
    setsubtitle(subtitle);
    setfile(file);
    setbg(bg);
    setvideo(video);

    dialogContent.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });  
  };

  return (
    <div>
      <button className="blog_a1" onClick={handleClickOpen}>
        Read More
      </button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <div ref={node => {
        dialogContent = node;
      }}>
          <div style={{backgroundImage: `url(${bg})`}} className={classes.blogHeader}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                </Typography>
                <IconButton className={classes.close} edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                    
              <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
              <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
              <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
            </svg>
          </div>

          <div className="ebcontent-wrapper">
            <div className={classes.blogBody}>
              <h1 className="blog-title mx-3" >{title}</h1>
              <h6 className="blog-subtitle my-5 mx-3" >{subtitle}</h6>
              <div className="blog_a2 my-5">
                  <iframe width="650" height="370" title={title} src={video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              {file}
            </div>
            <div className="ebside-items my-5 px-3 px-sm-5 pl-lg-0 pr-lg-3 pr-lg-0">
              {data.map((item) => <Item banner={item[2]} title={item[0]} subtitle={item[1]} setBlog={() => setBlog(item[0], item[1], item[4], item[3], item[2])}/>)}
            </div>
          </div>

          <svg className="wavy-divider" style={{transform: 'scale(-1,-1)', backgroundColor: '#333'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                   
              <path style={{opacity: '0.15', fill: 'white'}} d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
              <path style={{opacity: '0.3', fill: 'white'}} d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
              <path style={{opacity: '1', fill: 'white'}} d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
      </div>
      </Dialog>
    </div>
  );
}

function Item(props) {
  return (
    <div className="eb-sideitem-wrapper mb-4 mb-sm-5">
      <img className="eb-side-img mr-4" src={props.banner} alt="" />
      <div className="eb-text-wrapper">
        <h5 className="">{props.title}</h5>
        <h6 className="Bodytext-1regular-16ptleftmedium-lato">{props.subtitle}</h6>
        <button style={{color: 'darkorange'}} className="blog_a1" onClick={props.setBlog}>
          <b>Read Now</b>
        </button>  
      </div>
    </div>
  );
}

import React, { useContext, useState } from 'react'
import { DataContext } from '../Context/DataContext'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import VolunteerForm from '../Components/VolunteerForm.js';
import HostEvent from '../Components/HostEvent.js';
import  './collaborate.css';

function Collaborate(props) {
	const { heading, subheading, image } = useContext(DataContext);
	const [openD, setOpenD] = useState(false);
	const theme = useTheme();
  	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const handleOpenD = () => {
	    setOpenD(true)
	};

	const handleCloseD= () => {
	    setOpenD(false)
	};

	return (
		<div>
			<Header 
				headerClass="allCollaborateHeader"
				pageTitle="Collaborate" 
                pageSubTitle=""
            	svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "6"
				buttonto=""
				linkto = "/"
			/>

			<div className="collaborate-wrapper my-5">
				<div className="collaborate-content-wrapper">
					<div className="collaborate-header">{heading('col_art')}</div>
					<div className="collaborate-text-wrapper my-5">
						<p className="text-justify Bodytext-1regular-16ptleftdark-lato" dangerouslySetInnerHTML={{__html: subheading('col_art')}}></p>
					</div>
				</div>

				<div className="collaborate-types-wrapper">
					<div style={{backgroundColor: "white"}} className="collaborate-types-card my-5">
						<div className="collaborate-volunteer">
							<img src="https://image.flaticon.com/icons/svg/1807/1807426.svg" alt="" width="33%" height="auto"/>
							<h2 className="my-5">{heading('col_form_1')}</h2>
							<p className="text-justify Bodytext-1regular-16ptrightdark-lato my-4">{subheading('col_form_1')}</p>
							<VolunteerForm />
						</div>
					</div>
					<div style={{backgroundColor: "white"}} className="collaborate-types-card my-5">
						<div className="collaborate-volunteer">
							<img src="https://image.flaticon.com/icons/svg/1301/1301183.svg" alt="" width="33%" height="auto"/>
							<h2 className="my-5">{heading('col_form_2')}</h2>
							<p className="text-justify Bodytext-1regular-16ptrightdark-lato my-4">{subheading('col_form_2')}</p>
							<input className="styled-button-white-bg" style={{marginTop: "0em", marginBottom: "0em", borderRadius: "10em/1em"}} type='button' value='Donate Now' onClick={handleOpenD}/>
							<Dialog
								open={openD}
								onClose={handleCloseD}
								maxWidth='md'
								fullScreen={fullScreen}
								aria-labelledby="alert-dialog-title"
								aria-describedby="alert-dialog-description"
							>
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										<div className="donate-modal-wrapper">
											<img src={require("../images/decoration/don.webp")} alt=''/>
											<div className="donate-modal-main">
												<h2 className="mb-3">Contribute Offline</h2>
												<p className="my-3">You can send your cheque/DD drawn in favour of
												<b style={{color: "indigo"}}> LAKSH PRERNA DIVINE FOUNDATION</b> to the following address:</p>
												<p className="my-3"><b style={{color: "darkorange"}}>12 Ishwar Kripa part 2, Near Sardar Patel Stadium, Motera, Ahemdabad - 380005</b></p>
												<p className="my-3">Please enclose your complete contact details:
												Name, Address, Pin code, mobile and email address.</p>
												<p className="mt-3"><b style={{color: "black"}}>Please note that all contributions to the trust are eligible for tax deduction under section 80G.</b></p>
											</div>
											<IconButton style={{position: 'fixed', color: 'white', backgroundColor: '#fa6400',}} aria-label="close" className="donate-modal-close" onClick={handleCloseD}>
												<CloseIcon />
											</IconButton>
										</div>
									</DialogContentText>
								</DialogContent>
							</Dialog>
						</div>
					</div>
					<div style={{backgroundColor: "white"}} className="collaborate-types-card my-5">
						<div className="collaborate-volunteer">
							<img src="https://image.flaticon.com/icons/svg/1051/1051126.svg" alt="" width="33%" height="auto"/>
							<h2 className="my-5">{heading('col_form_3')}</h2>
							<p className="text-justify Bodytext-1regular-16ptrightdark-lato my-4">{subheading('col_form_3')}</p>
							<HostEvent />
						</div>
					</div>
				</div>
			</div>

            <Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>
		</div>
	)
}

export default Collaborate;

import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PmtForm from '../Components/PmtForm.js';
import UserDetails from '../Components/UserDetails.js';
import './pmt.css'
import '../Components/cards.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import '../fonts.css'
import {
    Link
} from "react-router-dom";

function Pmt(props) {
    const { heading, subheading, paragraph, image, state } = useContext(DataContext);

    const currentYear = () => {
		let year = ''
		// eslint-disable-next-line array-callback-return
		state.pmt_arch.map(item => {
            year = item.year
		})
		return year
    }
    
    const midYears = () => {
        return state.pmt_arch.map(row => {
            return row.year;
        }).filter((v, i, a) => a.indexOf(v) === i)
    }

    const firstYear = '2017';

    return (
        <div className="pmt-page-wrapper">
            <Header 
                headerClass="allPMTHeader"
                pageTitle={heading('pmt_header')}
                pageSubTitle={subheading('pmt_header')}
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "5"
                buttonto="Current Issue"
                amountToScroll={2200}
            />

            <div className="pmt-top-main-header my-5">{heading('pmt_vdo')}</div>

            <div className="pmt-archives-wrapper mx-auto">
                <h2 className="Bodytext-1regular-16ptrightdark-kulimpark pmt-top-main-subheader px-4 px-md-5">{subheading('pmt_vdo')}</h2>

                <div className="pmt-video-wrapper my-5 mx-auto">
                    <div className="pmt-video-intro-text">
                        <h2 style={{color: 'black', lineHeight:'35px'}} className="Subheading-1bold-36ptcenterdark-lato  px-4 px-md-5">{paragraph('pmt_vdo')}</h2>
                        <hr style={{width: '50px', height: '2px', backgroundColor: 'black', marginLeft: 'auto'}}/>
                        <div className="list-and-charges pt-4">
                            <ul className="pmt-video-list Bodytext-1regular-16ptleftdark-lato mr-3 mr-lg-5">
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_1')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_2')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_3')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_4')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_5')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_6')}}></li>
                                <li dangerouslySetInnerHTML={{__html: paragraph('pmt_vdo_7')}}></li>
                            </ul>
                            <ul className="pmt-video-charges Bodytext-1regular-16ptcenterdark-lato mr-lg-3 ml-lg-5">
                                <li><b><u>Subscription Charges (India)</u></b></li>
                                <li className="py-2"><u>(Print / E-Magazine)</u></li>
                                <li>{heading('pmt_price_1') + " - " + subheading('pmt_price_1') + " / " + paragraph('pmt_price_1')}</li>
                                <li>{heading('pmt_price_2') + " - " + subheading('pmt_price_2') + " / " + paragraph('pmt_price_2')}</li>
                                <li>{heading('pmt_price_3') + " - " + subheading('pmt_price_3') + " / " + paragraph('pmt_price_3')}</li>
                                <li className="pt-3"><b><u>Other Countries</u></b></li>
                                <li className="py-2"><u>(E-Magazine Only)</u></li>
                                <li>{heading('pmt_price_4') + " - " + subheading('pmt_price_4')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="pmt-video-iframe mb-5">
                        <iframe width="650" height="370" title="About Prabhu Mein Tera magazine" src={paragraph('pmt_price_4')} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>

                <div className="recent-edition-wrapper-outer">
                    <svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                   
                        <path style={{opacity: '0.15', fill: 'white'}} d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
                        <path style={{opacity: '0.3', fill: 'white'}} d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
                        <path style={{opacity: '1', fill: 'white'}} d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
                    </svg>
                    <div className="recent-edition-wrapper mx-auto px-5">
                        <div className="mx-auto recent-edition-image-wrapper">
                            <img className="recent-edition-image" src="https://lpdf.in/images/pmt/covers_sm/recent.jpg" alt="" width="100%" height="auto"/>
                            <div className="mt-5 mx-n5">
                                <p className="e1 Bodytext-1regular-16ptcenterdark-lato"><b>Free Highlights</b></p>
                                <hr style={{width: '50px', backgroundColor: 'black'}} className="e1"/>
                                <div className="pmt-cover-img-links-wrapper">
                                    <UserDetails link={heading('free_highlights')} title="Hindi"/>
                                    <UserDetails link={subheading('free_highlights')} title="Gujarati"/>
                                    <UserDetails link={paragraph('free_highlights')} title="Marathi"/>
                                </div>
                            </div>
                        </div>
                        <div className="recent-edition-text-wrapper my-5 my-lg-0">
                            <h2 className="recent-edition-header Subheading-1bold-36ptleftdark-lato">{heading('pmt_latest')}</h2>
                            <h3 className="recent-edition-subheader Bodytext-1regular-16ptcenterdark-lato" title="English available in E-Magazine only">{subheading('pmt_latest')}</h3>
                            <p className="text-justify recent-edition-detail-text py-4">
                                {paragraph('pmt_latest')}
                            </p>
                            <div className="recent-edition-index-wrapper">
                                <ul className="recent-edition-index mr-lg-2 mr-xl-5">
                                    <li className="my-3">
                                        <img src="https://lpdf.in/images/pmt/svgs/1.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">
                                            <p style={{color: '#f86400'}} className="recent-edition-index-text">{heading('pmt_latest_1')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_1')}</p>
                                        </div>
                                    </li>
                                    <li className="my-5">
                                        <img src="https://lpdf.in/images/pmt/svgs/2.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">
                                            <p style={{color: 'deeppink'}} className="recent-edition-index-text">{heading('pmt_latest_2')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_2')}</p>
                                        </div>
                                    </li>
                                    <li className="my-3">
                                        <img src="https://lpdf.in/images/pmt/svgs/3.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">
                                            <p style={{color: 'purple'}} className="recent-edition-index-text">{heading('pmt_latest_3')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_3')}</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="recent-edition-index ml-lg-2 ml-xl-5">
                                    <li className="my-3">
                                        <img src="https://lpdf.in/images/pmt/svgs/4.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">            
                                            <p style={{color: 'green'}}className="recent-edition-index-text">{heading('pmt_latest_4')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_4')}</p>
                                        </div>
                                    </li>
                                    <li className="my-5">
                                        <img src="https://lpdf.in/images/pmt/svgs/5.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">
                                            <p style={{color: 'darkslategrey'}}className="recent-edition-index-text">{heading('pmt_latest_5')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_5')}</p>
                                        </div>
                                    </li>
                                    <li className="my-3">
                                        <img src="https://lpdf.in/images/pmt/svgs/6.svg" alt="" width="50px" height="50px"/>
                                        <div className="recent-edition-index-text-wrapper">
                                            <p style={{color: 'red'}} className="recent-edition-index-text">{heading('pmt_latest_6')}</p>
                                            <p className="recent-edition-index-subtext my-3">{subheading('pmt_latest_6')}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="recent-edition-buttons my-4">
                                <div className="mr-4 mb-1 mb-sm-0">
                                    <Link to="/PrabhuMeinTera/Subscribe"><PmtForm buttontext="Subscribe Now" type={1} openform={props.openform}/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">                    
                        <path d="M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
                        <path d="M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"></path>
                        <path d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
                    </svg>
                </div>

                <div className="prev-edition-wrapper mx-5 mx-xl-auto">
                    <h2 className="recent-edition-header mt-5 Subheading-1bold-36ptleftdark-lato">{heading('pmt_arch')}</h2>
                    <h4 className="mb-5 Bodytext-1regular-16ptleftdark-lato">{subheading('pmt_arch')}</h4>

                    <div role="tabpanel">
                        <ul className="nav nav-tabs" style={{borderBottom: 'none'}} role="tablist">
                            <li role="presentation" className="customNavigation customNavigation-first active"><a href="#first" aria-controls="first" role="tab" data-toggle="tab">{currentYear() + ' (Highlights)'}</a></li>
                            {midYears().slice(1, -1).reverse().map((row, index) => {
                                return <li role="presentation" className="customNavigation customNavigation-mid"><a href={"#l" + index} aria-controls={"l" + index} role="tab" data-toggle="tab">{row}</a></li>
                            })}
                            <li role="presentation" className="customNavigation customNavigation-last"><a href="#last" aria-controls="last" role="tab" data-toggle="tab">{firstYear}</a></li>
                        </ul>
                        <div className="tab-content my-5">
                            <div role="tabpanel" className="tab-pane fade show active" id="first">
                                <div className="a1">
                                    {state.pmt_arch.map(row => {
										return (row.year.startsWith(currentYear())) ? <ArcIssue src={row.thumbnail} time={row.time} h={row.hindi} g={row.gujarati} m={row.marathi}/> : undefined;
									})}
                                </div>
                            </div>
                            {midYears().slice(1, -1).reverse().map((year, index) => {
                                return <div role="tabpanel" className="tab-pane fade" id={"l" + index}>
                                    <div className="a1">
                                        {state.pmt_arch.map(row => {
                                                return  (row.year.startsWith(year)) ? <ArcIssue src={row.thumbnail} time={row.time} h={row.hindi} g={row.gujarati} m={row.marathi}/> : undefined
                                        })}
                                    </div>
                                </div>
                            })}
                            <div role="tabpanel" className="tab-pane fade" id="last">
                                <div className="a1">
                                    {state.pmt_arch.map(row => {
										return (row.year.startsWith(firstYear)) ? <ArcIssue src={row.thumbnail} time={row.time} h={row.hindi} g={row.gujarati} m={row.marathi}/> : undefined;
									})}
                                </div>
                            </div>
                      </div>
                    </div>
                </div>
            </div>

            <div className="mb-5 pmt-recent-wrapper">
                <h2 className="Subheading-1bold-36ptcenterdark-lato">{heading('pmt_artc')}</h2>
                <hr style={{width: '10%', height: '2px', backgroundColor: 'black', marginLeft: '45%'}}/>
                <h4 style={{maxWidth: '1250px'}} className="my-5 px-5 mx-auto Bodytext-1regular-16ptcenterdark-lato" dangerouslySetInnerHTML={{__html: subheading('pmt_artc')}}></h4>
                <div className="pmt-recent-wrapper-inner">
                    {state.pmt_artc.map(row => {
                        return <HighlightsCard
                            color={row.color}
                            darkcolor={row.darkcolor}
                            url={row.icon}
                            article={row.hindi}
                            articleG={row.gujarati}
                            articleM={row.marathi}
                            articleE={row.english}
                            subname={row.subname}
                            name={row.name}
                            content={<p style={{display: 'inline'}} dangerouslySetInnerHTML={{__html: row.content}}></p>}
                        />
                    })}
                </div>
            </div>

            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
        </div>
    )
}

function ArcIssue(props) {
    return (
        <div className="b2">
            <div className="b3">
                <div className="b1">
                    <div className="c1">
                        <img className="d1" src={props.src} alt=""/>
                    </div>
                </div>
            </div>
            <div>
                <p className="e1 Bodytext-1regular-16ptcenterdark-lato">{props.time}</p>
                <hr style={{width: '100px', backgroundColor: 'black'}} className="e1"/>
                <div className="pmt-cover-img-links-wrapper">
                    <UserDetails link={'http://online.anyflip.com/dielv/' + props.h} title="Hindi"/>
                    {props.g ? <UserDetails link={'http://online.anyflip.com/dielv/' + props.g} title="Gujarati"/> : <span></span>}
                    {props.m ? <UserDetails link={'http://online.anyflip.com/dielv/' + props.m} title="Marathi"/> : <span></span>}
                    {props.e ? <UserDetails link={'http://online.anyflip.com/dielv/' + props.e} title="English"/> : <span></span>}
                </div>
            </div>
        </div>
    )
}

function HighlightsCard(props ){
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{width: "400px", cursor: 'default'}} className="card-a-types-card my-5 mx-4 mx-lg-4 mx-xl-4">
            <div className="card__container">
                <div className="card p-4">
                    <div style={{backgroundColor: props.color}} className="card__image-container-pmt">
                            <img style={{width: "33%"}} className="card-image" src={props.url} alt="" />
                        </div>
                    <svg className="card__svg" viewBox="0 0 1000 400">
                        <path style={{opacity: "0.15"}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z" fill="#fff"></path>
                        <path style={{opacity: "0.3"}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z" fill="#fff"></path>
                        <path style={{opacity: "1"}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z" fill="#fff"></path>                 
                    </svg>              
                    <div className="card__content" style={{lineHeight: "1.5em"}}>
                        <h1 className="card__title" style={{fontFamily: "Lato", fontWeight: "600", fontSize: "1.7em", lineHeight: "1.8em"}}>{props.name}</h1>
                        <h3 className="card__sub__title my-4" style={{color: '#666', fontFamily: "Kulimpark", fontWeight: "600", fontSize: "1.4em", lineHeight: "2em"}}>{props.subname}</h3>
                        <p style={{fontSize: '1em'}} className="card__text text-justify Bodytext-1regular-16ptcenterdark-lato">
                            {props.content}                 
                            <b className="f1" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                ........Read More
                            </b>     
                        </p>
                    </div>
                    <Menu
                        style={{left: '110px'}}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <a style={{textDecoration: 'none', color: 'black'}} href={props.articleE} target="_blank"><MenuItem onClick={handleClose}>English</MenuItem></a>
                        <a style={{textDecoration: 'none', color: 'black'}} href={props.article} target="_blank"><MenuItem onClick={handleClose}>Hindi</MenuItem></a>
                        <a style={{textDecoration: 'none', color: 'black'}} href={props.articleG} target="_blank"><MenuItem onClick={handleClose}>Gujarati</MenuItem></a>
                        <a style={{textDecoration: 'none', color: 'black'}} href={props.articleM} target="_blank"><MenuItem onClick={handleClose}>Marathi</MenuItem></a>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default Pmt;
import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './dsr.css'
import Header from '../Components/Header.js'
import DsrForm from '../Components/DsrForm.js'
import Footer from '../Components/Footer.js'
import { SliderLeftLong } from '../Components2/SliderLeft.js'
import { SliderRightLong } from '../Components2/SliderRight.js'
import '../fonts.css'

function Dsr(props) {
	const { state, heading, subheading } = useContext(DataContext);

	return(
		<div>
			<Header 
				headerClass="allDSRHeader"
				pageTitle={heading('dsr_header')} 
                pageSubTitle={subheading('dsr_header')}
            	svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>

			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeftLong
					img1="https://lpdf.in/images/dsr/poster3.jpg"
					img2="https://lpdf.in/images/dsr/poster4.jpg"
					img3="https://lpdf.in/images/dsr/poster5.jpg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('dsr_c1_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato pt-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('dsr_c1_p1')}}></p>
						<p className="Bodytext-1regular-16ptcenterdark-lato pb-md-0" dangerouslySetInnerHTML={{__html: subheading('dsr_c1_p2')}}></p>
						<div className="slider-long-extra-text">
					    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('dsr_c1_p3')}}></p>
						</div>
					</aside>
		    	</div>
		    </div>
		    <div className="slider-long-extra-text-display-medium-sc">
		    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('dsr_c1_p3')}}></p>
			</div>


			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-right-slider-wrapper-display py-5">
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pr-xl-5">
					<aside>
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('dsr_c1_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato pt-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('dsr_c2_p1')}}></p>
						<p className="Bodytext-1regular-16ptcenterdark-lato pb-md-0" dangerouslySetInnerHTML={{__html: subheading('dsr_c2_p2')}}></p>
						<div className="slider-long-extra-text">
					    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('dsr_c2_p3')}}></p>
						</div>
					</aside>
		    	</div>
				<SliderRightLong
					img1="https://lpdf.in/images/dsr/poster4.jpg"
					img2="https://lpdf.in/images/dsr/poster5.jpg"
					img3="https://lpdf.in/images/dsr/poster3.jpg"
				/>
		    </div>
		    <div className="slider-long-extra-text-display-medium-sc">
		    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('dsr_c2_p3')}}></p>
			</div>


			<div className="main-video-dsr">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: 'white'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: 'white'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: 'white'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className="main-video-container-dsr">
					<h2 className="Subheading-1bold-36ptleftwhite-kulimpark">{heading('dsr_act_1')}</h2>
					<hr/>
					<h3 className="Subtitle-2regular-26ptleftwhite-kulimpark text-justify text-md-center my-5">{subheading('dsr_act_1')}</h3>
					<div className="main-video-container-dsr-inner">
						<div className="dsr-iframe">
                            <iframe width="100%" height="100%" title="dsr_act" src={subheading('dsr_act_2')} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
						<ul className="dsr-list my-5 mb-lg-0 mt-lg-3">
							{state.home_text.map(row => {
								return (row.name.startsWith('dsr_act')) ? <li className="Subtitle-1bold-26ptleftwhite-lato text-left">{row.paragraph}</li> : undefined;
							})}
						</ul>
                    </div>
                </div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>

			<h3 className="Subheading-1bold-36ptrightmedium-kulimpark">Seminar Registration</h3>
			<div style={{display: 'flex', justifyContent: 'center', marginBottom: '40px', marginTop: '30px'}}>
				<Link to="/DivyaShishuRatna/SeminarRegistration"><DsrForm openform={props.openform} buttontext="Register Now"/></Link>
			</div>

			<Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>

		</div>
	)
}

export default Dsr;
import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './ascentoflife.css'
import classNames from 'classnames';
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import SliderLeft from '../Components2/SliderLeft.js'
import SliderRight from '../Components2/SliderRight.js'
import CardB from '../Components/CardB.js'
import '../fonts.css'

function AscentOfLife(props) {
	const { state, heading, subheading, image } = useContext(DataContext);

	const faqClick = (event) => {
		var target = event.target;

		target.classList.toggle("curr");
		var panel = target.nextElementSibling;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + "px";
		}
	}

	return(
		<div>
			<Header 
				headerClass="allAscentoflifeHeader"
				pageTitle={heading('aol_header')} 
				pageSubTitle={subheading('aol_header')}
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>
			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeft 
					img1="https://lpdf.in/images/ascentoflife/ban1.jpg"
					img2="https://lpdf.in/images/ascentoflife/ban2.jpg"
					img3="https://lpdf.in/images/ascentoflife/ban3.jpg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
			    		<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('aol_c1_p1')}</h2>
					    <p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('aol_c1_p1')}}></p>
					</aside>
		    	</div>
		    </div>

		    <div className="content-ekadashi-left-slider-wrapper content-ekadashi-right-slider-wrapper-display py-5">
		    	<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pr-xl-5">
					<aside>	
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('aol_c2_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato py-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('aol_c2_p1')}}></p>
					</aside>
				</div>
				<SliderRight
					img1="https://lpdf.in/images/ascentoflife/ban2.jpg"
					img2="https://lpdf.in/images/ascentoflife/ban3.jpg"
					img3="https://lpdf.in/images/ascentoflife/ban1.jpg"
				/>
			</div>

			<div className="three-i-principle">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: 'white'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: 'white'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: 'white'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className="three-i-principle-header">
					<h2 className="Subheading-1bold-36ptcenterwhite-lato">{heading('aol_3i')}</h2>
				</div>
				
				<div className="three-i-principle-content-wrapper">
					<span className="three-i-principle-content">
						<article className="Subtitle-2regular-26ptleftwhite-kulimpark my-5 text-justify">
							{subheading('aol_3i')}
						</article>
						<div className="three-i-principle-content-point pt-5">
							<div className="three-i-principle-icon">
								<img 
									src={require("../images/ascentoflife/intelligence.png")}
									alt='' width="70px" height="70px"
								/>
							</div>
							<div className="three-i-principle-content-detail mt-1">
								<div className="three-i-principle-content-point-header">
									<h3 className="Subtitle-1bold-26ptleftwhite-lato">{heading('aol_3i_p1_1')}</h3>
								</div>
								<ul className="mt-5 ml-n5 mt-sm-4 ml-sm-0">
									{state.home_text.map(row => {
										return (row.name.startsWith('aol_3i_p1')) ? <li className="Bodytext-1regular-16ptleftwhite-lato">{row.subheading}</li> : undefined;
									})}
								</ul>
							</div>
						</div>

						<div className="three-i-principle-content-point pt-5">
							<div className="three-i-principle-icon">
								<img 
									src={require("../images/ascentoflife/invaluable.png")}
									alt='' width="70px" height="70px"
								/>
							</div>
							<div className="three-i-principle-content-detail mt-1">
								<div className="three-i-principle-content-point-header">
									<h3 className="Subtitle-1bold-26ptleftwhite-lato">{heading('aol_3i_p2_1')}</h3>
								</div>
								<ul className="mt-5 ml-n5 mt-sm-4 ml-sm-0">
									{state.home_text.map(row => {
										return (row.name.startsWith('aol_3i_p2')) ? <li className="Bodytext-1regular-16ptleftwhite-lato">{row.subheading}</li> : undefined;
									})}
								</ul>
							</div>
						</div>

						<div className="three-i-principle-content-point pt-5">
							<div className="three-i-principle-icon">
								<img 
									src={require("../images/ascentoflife/possible.png")}
									alt='' width="70px" height="70px"
								/>
							</div>
							<div className="three-i-principle-content-detail mt-1">
								<div className="three-i-principle-content-point-header">
									<h3 className="Subtitle-1bold-26ptleftwhite-lato">{heading('aol_3i_p3_1')}</h3>
								</div>
								<ul className="mt-5 ml-n5 mt-sm-4 ml-sm-0">
									{state.home_text.map(row => {
										return (row.name.startsWith('aol_3i_p3')) ? <li className="Bodytext-1regular-16ptleftwhite-lato">{row.subheading}</li> : undefined;
									})}
								</ul>
							</div>
						</div>
					</span>
				</div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>

			<div className="ekadashi-types-main">
				<div className="ekadashi-types-header">
					<h2 className="text-center Subheading-1bold-36ptleftdark-lato">SPECIAL ASPECTS</h2>
				</div>
				<div className="ekadashi-types-card-wrapper-outer">
					<div className={classNames("ekadashi-types-card-wrapper")}>
						{state.home_text.map(row => {
							return (row.name.startsWith('aol_aspects')) ? 
							<CardB
								url={image(row.name)}
								name={row.heading}
								readMore={false}
								content={row.subheading}
							/> : undefined;
						})}
					</div>
				</div>
			</div>

			<div className="faq">
				<div className="faq-header my-5">
					<h2 className="Subheading-1bold-36ptleftdark-lato">FREQUENTLY ASKED QUESTIONS</h2>
				</div>

				{state.home_text.map(row => {
					return (row.name.startsWith('aol_faq_p') && row.name.endsWith('_1')) ?
						<div>
							<button className="accordion Subtitle-2regular-26ptleftdark-kulimpark" onClick={faqClick}>{row.heading}</button>
							<div className="panel">
								<ul className="my-4">
									{state.home_text.map(row1 => {
										return (row1.heading === row.heading) ? <li className="Bodytext-1regular-16ptleftdark-lato">{row1.subheading}</li> : undefined;
									})}
								</ul>
							</div>
						</div> : undefined;
				})}
			</div>

			<Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>
		</div>
	)
}


export default AscentOfLife;
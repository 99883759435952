/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useState } from 'react'
import axios from 'axios'
import { DataContext } from '../Context/DataContext'
import  './contactus.css'
import  './collaborate.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import { TextField, Typography, Grid, Snackbar } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import { ButtonA } from '../Components/Button.js'
import '../fonts.css'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ContactUs(props) {
	const { heading, subheading, paragraph } = useContext(DataContext);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [text, setText] = useState('');
	const [emailerr, setemailerr] = useState('');
	const [openloading, setopenloading] = React.useState(false);

	const [message, setmessage] = useState(false);
	const [formstatus, setformstatus] = useState('error');
	const [logtext , setlogtext] = useState('');

	const handleInputChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		if(name==='name')
		  setName(value);
		if(name==='text')
		  setText(value);
		if(name==='email') {
			setemailerr('');
		  	setEmail(value);
			// eslint-disable-next-line no-useless-escape
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (reg.test(value) === false) {
				setemailerr('Email is Not Correct');
				return;
			}
			else {
				setemailerr('');
			}
		}
	};

	const handleFormSubmit = async () => {
		setopenloading(true);
		const formData = new FormData()
		if (name && email && text) {
			formData.append("name", name)
			formData.append("email", email);
			formData.append("text", text);

			setmessage(true);
			setformstatus('info');
			setlogtext('Submitting');
			await axios({
		      url: 'https://lpdf.in/lpdf_backend/home/insert_into_contact_form.php',
		      method: 'post',
		      data: formData,
		      responseType: 'json'
		    })
		    .then(() => {
				setmessage(true);
				setformstatus('success');
				setlogtext('Details Submitted Successfully');
				setName('');
				setEmail('');
				setText('');
				setemailerr('');
		    })
		    .catch(err => {
				console.log('err', err)
				setmessage(true)
				setformstatus('error')
				setlogtext('Something went wrong')
			})
		}
		else {
			setmessage(true);
			setformstatus('error');
			setlogtext('Please fill all details');
		}
		setopenloading(false);
	};

	return (
		<div>
			<Header 
				headerClass="allGalleryHeader"
				pageTitle="About Us" 
                pageSubTitle=""
            	svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
                curr = "2"
				buttonto=""
				linkto = "/"
			/>
			<div className="contactus-wrapper mx-auto">
				<div className="collaborate-header">{heading('cont_loc')}</div>
				<div className="contactus-content-wrapper">
					<div className="contactus-addr-wrapper mx-auto">
						<div className="contactus-addr">
							<p><i className="fa fa-map-marker mr-2" aria-hidden="true"/>{subheading('cont_loc')}</p>
						</div>
						<div className="contactus-phone">
							<p><i className="fa fa-phone mr-2" aria-hidden="true"/>{paragraph('cont_loc')}</p>
						</div>
						<div className="contactus-social-media">
							<a href="https://www.facebook.com/Lpdfshriji"><i className="fa fa-facebook mr-2" aria-hidden="true"></i></a>
							<a href="https://www.instagram.com/prernamurti"><i className="fa fa-instagram mx-2" aria-hidden="true"></i></a>
							<a href="https://twitter.com/lpdf_ngo?lang=en"><i className="fa fa-twitter mx-2" aria-hidden="true"></i></a>
							<a href="https://www.youtube.com/channel/UCPTnWvvMKf2WYcK832ZMh4A"><i className="fa fa-youtube-play ml-2" aria-hidden="true"></i></a>
						</div>
						<iframe className="contactus-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7340.08964388066!2d72.59628769999999!3d23.095454999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83c6bddebbbf%3A0xa86d7333b878ec49!2sIswar%20Krupa%20Society%20Vibhag-2!5e0!3m2!1sen!2sin!4v1596093459310!5m2!1sen!2sin" width="auto" height="auto" title="lpdf_loc" style={{border: '0'}} aria-hidden="false"></iframe>
					</div>
					<div className="contactus-form-wrapper mt-5 mt-lg-0 mx-auto">
						<Typography variant="h6" className="Bodytext-1regular-16ptleftmedium-lato mb-4">
							Leave us a message
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									autoComplete="name"
									name="name"
									variant="outlined"
									required
									fullWidth
									id="name"
									label="Name"
									value={name}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email"
									name="email"
									value={email}
									autoComplete="email"
                					onChange={handleInputChange}
								/>
								<p style={{color: 'orangered', fontSize: emailerr === '' ? '0rem' : '0.8rem', marginLeft: '8px'}}>{emailerr}</p>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									multiline
									rows={6}
									required
									fullWidth
									id="text"
									label="Write your message here"
									name="text"
									value={text}
                					onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} className="mt-4 text-right">
								<ButtonA buttonto="Submit Details" onClick={handleFormSubmit}/>
							</Grid>
							<Snackbar open={message} autoHideDuration={6000} onClose={() => setmessage(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                <Alert onClose={() => setmessage(false)} severity={formstatus}>
                                    {logtext}
                                </Alert>
                            </Snackbar>
                            <Backdrop className="contactus-backdrop" open={openloading} onClick={()=>{}}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
						</Grid>
					</div>
				</div>
            </div>
            <Footer
                svgPath1 = "M 1000 299 l 2 -279 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -232 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"
                svgPath2 = "M 1000 286 l 2 -252 c -157 -43 -302 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -145.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 10.61 v 22.39 z"
                svgPath3 = "M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"
            />
		</div>
	)
}

export default ContactUs;
import React, {useEffect, useState} from 'react';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useDailogStyles = makeStyles((theme) => ({
	text: {
		color: '#222'
	},
	dailogbutton: {
		color: '#fff'
	},
	uploadedImg: {
		height: '100px',
		paddingLeft: '20px'
	}
}));

function DeleteDialog(props) {
	const classes = useDailogStyles();
	const [open, setOpen] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("id", props.entry[0].id);
		axios.post('https://lpdf.in/lpdf_backend/home/delete_from_event_form.php', formData).then(() => {
			window.location.reload();
			setOpen(false);
		}
		).then((error) => {
			console.log(error);
		});
	};

	return (
	<div>
		<Tooltip title="Delete">
			<IconButton aria-label="delete" onClick={handleClickOpen} style={{ border:"none", outline:"none" }}>
				<DeleteIcon className={classes.dailogbutton}/>
			</IconButton>
		</Tooltip>
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="responsive-dialog-title"
		>
			<DialogTitle id="responsive-dialog-title">{"Alert"}</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					Are you sure you want to remove this item ?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => setOpen(false)} color="primary" style={{ border:"none", outline:"none" }}>
					Cancle
				</Button>
				<Button onClick={handleClose} color="primary" autoFocus style={{ border:"none", outline:"none" }}> 
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	</div>
	);
}

const headCells = [
    { id: 'id', label: 'Id'},
    { id: 'timestamp', label: 'Upload time' },
    { id: 'name', label: 'Name' },
    { id: 'eaddr', label: 'Event Address' },
    { id: 'mob', label: 'Mobile' },
    { id: 'email', label: 'Email Id' },
    { id: 'prog_txt', label: 'Event Category' }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
		boxShadow: 'none',
    },
    table: {
        minWidth: 500,
		marginTop: '50px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    checkboxcolor: {
		color: '#222',
		'&$checked': {
			color: '#222',
		},
	},
	fab: {
	  position: 'fixed',
	  bottom: theme.spacing(2),
	  right: '90px',
	  zIndex: 1000
	},
	fab2: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: '20px',
		zIndex: 1000
	}
}));

export default function EventTable(props) {
	const classes = useStyles();
	const [selected, setSelected] = useState([]);
	const [rows, setRows] = useState([])
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
        let textData;
        axios.get('https://lpdf.in/lpdf_backend/home/get_event_form.php').then(response => {
            textData = response.data;
            console.log(textData);
            setRows(textData);
        })
        .catch(error => {
            console.log(error);
        });
	}, []);

	const handleClick = (event, name) => {
		if(selected.length === 0)
			setSelected([name]);
		else if(name === selected[0])
			setSelected([]);
		else
			setSelected([name]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	return (
		<div className={classes.root}>
		<Paper className={classes.paper}>
			{selected.length > 0 ? (
				<Fab aria-label="delete button" className={classes.fab2} color="secondary">
					<DeleteDialog entry={rows.filter(item => item.id === selected[0])}/>
				</Fab>
			):  <div></div>
			}
			<Typography align="center" className={classes.title} variant="h3" id="tableTitle" component="div">
				Host an Event Form
			</Typography>
			<TableContainer>
			<Table
				className={classes.table}
				aria-labelledby="event"
				aria-label="event table"
			>
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox">
						</TableCell>
						{headCells.map((headCell) => (
						<TableCell
							key={headCell.id}
							align='center'
							padding='default'
						>
							{headCell.label}
						</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
				{rows
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((row, index) => {
					const isItemSelected = isSelected(row.id);
					const labelId = `enhanced-table-checkbox-${index}`;

					return (
						<TableRow
							hover
							onClick={(event) => handleClick(event, row.id)}
							role="checkbox"
							aria-checked={isItemSelected}
							tabIndex={-1}
							key={row.id}
							selected={isItemSelected}
						>
						<TableCell align="center" padding="checkbox">
							<Checkbox
								checked={isItemSelected}
								color="secondary"
								classes={{
									root: classes.checkboxcolor,
								}}
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</TableCell>
						<TableCell align="center" component="th" id={labelId} scope="row" padding="none">
							{row.id}
						</TableCell>
						<TableCell align="center">{row.time_stamp}</TableCell>
						<TableCell align="center">{row.name}</TableCell>
						<TableCell align="center">{row.eaddr}</TableCell>
                        <TableCell align="center">{row.mob}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.prog_txt}</TableCell>
						</TableRow>
					);
					})}
				</TableBody>
			</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</Paper>
		</div>
	);
}

import React from 'react';
import DataContextProvider from './Context/DataContext'
import './App.css';
import Home from './Pages/Home.js'
import AscentOfLife from './Pages/AscentOfLife.js'
import Ekadashi from './Pages/Ekadashi.js'
import ThreeGproject from './Pages/3Gproject.js'
import AnandUtsav from './Pages/AnandUtsav.js'
import AyushmanBhav from './Pages/AyushmanBhav.js'
import Dsr from './Pages/Dsr.js'
import WomenEmp from './Pages/WomenEmp.js'
import Pmt from './Pages/Pmt.js'
import Welfare from './Pages/Welfare.js'
import Collaborate from './Pages/Collaborate.js'
import Gallery from './Pages/Gallery.js'
import AboutUs from './Pages/AboutUs.js'
import ContactUs from './Pages/ContactUs.js'
import Gaay from './Pages/Gaay.js'
import Gita from './Pages/Gita.js'
import Ganga from './Pages/Ganga.js'
import AdminApp from './Admin/AdminApp'
import Login from './Admin/Login'
import ScrollToTop from './ScrollToTop'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

function App() {

    let content = <DataContextProvider>
            <Router>
                <ScrollToTop/>
                <Switch>
                    <Route path="/3G-Project/Gaay">
                        <Gaay/>
                    </Route>
                    <Route path="/3G-Project/ShriGitaPrerna">
                        <Gita/>
                    </Route>
                    <Route path="/3G-Project/Ganga">
                        <Ganga/>
                    </Route>
                    <Route path="/PrabhuMeinTera/Subscribe">
                        <Pmt openform/>
                    </Route>
                    <Route path="/PrabhuMeinTera">
                        <Pmt/>
                    </Route>
                    <Route path="/3G-Project">
                        <ThreeGproject/>
                    </Route>
                    <Route path="/WelfareActivities">
                        <Welfare/>
                    </Route>
                    <Route path="/WomenEmpowerment">
                        <WomenEmp/>
                    </Route>
                    <Route path="/AnandUtsav">
                        <AnandUtsav/>
                    </Route>
                    <Route path="/DivyaShishuRatna/SeminarRegistration">
                        <Dsr openform/>
                    </Route>
                    <Route path="/DivyaShishuRatna">
                        <Dsr/>
                    </Route>
                    <Route path="/AscentOfLife">
                        <AscentOfLife/>
                    </Route>
                    <Route path="/AyushmanBhav">
                        <AyushmanBhav/>
                    </Route>
                    <Route path="/BodyServicingDay">
                        <Ekadashi/>
                    </Route>
                    <Route path="/Collaborate">
                        <Collaborate/>
                    </Route>
                    <Route path="/Gallery">
                        <Gallery/>
                    </Route>
                    <Route path="/ContactUs">
                        <ContactUs/>
                    </Route>
                    <Route path="/AboutUs">
                        <AboutUs/>
                    </Route>
                    <Route path="/850274/Admin/Login">
                        <Login />
                    </Route>
                    <Route path="/850274/Admin">
                        {(sessionStorage.getItem("876789") === "admin@lpdf.in" 
                            && sessionStorage.getItem("453465") === "u233773154_lpdf")
                                ? <AdminApp /> :  <Login />}
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
            </Router>
        </DataContextProvider>

    return (
        content
    );
}

export default App;

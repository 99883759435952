import React from 'react'
import classNames from 'classnames'
import './footer.css'
import './header.css'
import {
	Link
} from "react-router-dom";


function Item(props) {
	return(
		<h4 className="link-item Bodytext-1regular-16ptleftwhite-lato">{props.content}</h4>
	)
}

class Footer extends React.Component {
	constructor() {
		super();
	}
	render() {
		return(
			<div style={{backgroundColor: '#333'}}>
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none" width="100%" height="100">					
					<path d={this.props.svgPath1}></path>
					<path d={this.props.svgPath2}></path>
					<path d={this.props.svgPath3}></path>
				</svg>
				<div className="footer-wrapper">
					<div className="footer-main">
						<div className="social-media">
							<h3 className="Subheading-1regular-36ptleftwhite-lato"><u>Follow us</u></h3>
							<div className="row justify-content-end align-content-end lpdf-smc-wrapper mt-4" id="navbarNavDropdown">
					    		<div className="lpdf-smc col align-self-end">
				    				<a href="https://www.facebook.com/Lpdfshriji"><img 
				    					className="mr-1"
					    				src={require("../images/fb.svg")}
					    				alt='' width="35px" height="35px"
					    			/></a>
				    				<a href="https://www.instagram.com/prernamurti"><img 
				    					className="ml-1"
				    					src={require("../images/insta.svg")}
				    					alt='' width="35px" height="35px"
				    				/></a>
				    			</div>
				    			<div className="lpdf-smc col">
				    				<a href="https://twitter.com/lpdf_ngo?lang=en"><img 
				    					className="mr-1"
				    					src={require("../images/twitter.svg")}
				    					alt='' width="35px" height="35px"
				    				/></a>
				    				<a href="https://www.youtube.com/channel/UCPTnWvvMKf2WYcK832ZMh4A"><img 
				    					className="ml-1"
				    					src={require("../images/youtube.svg")}
				    					alt='' width="35px" height="35px"
				    				/></a>
					    		</div>
					    	</div>
						</div>
						<div className="quick-links">
							<h3 className="quick-links-main-header Subheading-1regular-36ptleftwhite-lato"><u>Quick Links</u></h3>
							<div className="quick-links-inner">
								<div className="quick-links-inner-items">
									<h3 className="quick-links-header Subheading-1regular-36ptleftwhite-lato">Get Involved</h3>
									<Item content={<Link to="/AboutUs" className="link">Vision and Mission</Link>}/>
									<Item content={<Link to="/Collaborate" className="link">Be a Volunteer</Link>}/>
									<Item content={<Link to="/ContactUs" className="link">Contact Us</Link>}/>
								</div>
								<div className="vertical-bar">
								</div>
								<div className="quick-links-inner-items-wrapper">
									<div className="quick-links-inner-items-projects mr-md-4 mr-lg-5">
										<h3 className="quick-links-header Subheading-1regular-36ptleftwhite-lato">Projects</h3>
										<Item content={<Link to="/3G-Project" className="link">3G Project</Link>}/>
										<Item content={<Link to="/AnandUtsav" className="link">Anand Utsav</Link>}/>
										<Item content={<Link to="/AscentofLife" className="link">Ascent of Life</Link>}/>
										<Item content={<Link to="/AyushmanBhav" className="link">Ayushman Bhava</Link>}/>
									</div>
									<div className="quick-links-inner-items-projects ml-md-4 ml-lg-5">
										<h3 className="pseudo-dot quick-links-header Subheading-1regular-36ptleftwhite-lato" style={{color: 'transparent'}}>.</h3>
										<Item content={<Link to="/BodyServicingDay" className="link">Body Servicing Day</Link>}/>
										<Item content={<Link to="/DivyaShishuRatna" className="link">Divya Shishu Ratna</Link>}/>
										<Item content={<Link to="/WomenEmpowerment" className="link">'Devi' Being Women</Link>}/>
										<Item content={<Link to="/WelfareActivities" className="link">Welfare Activities</Link>}/>
									</div>
								</div>
								<div className="vertical-bar">
								</div>
								<div className="quick-links-inner-items">
									<h3 className="quick-links-header Subheading-1regular-36ptleftwhite-lato">Related</h3>
									<Item content={<a rel="noopener noreferrer" className={classNames("link")} href="http://www.prernamurti.com" target="_blank">Shriji</a>}/>
									<Item content={<Link to="/PrabhuMeinTera" className="link">Magazine</Link>}/>
									<Item content={<Link to="/Gallery" className="link">Gallery</Link>}/>
								</div>
							</div>
						</div>
					</div>
					<hr/>
					<div className="footer-end">
						<p>© 2020, lpdf.in, All rights reserved</p>
					</div>
				</div>
			</div>
		)	
	}
}

export default Footer;

import React, { useState } from 'react';
import * as vendor from 'css-vendor'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
	Link, Redirect
} from "react-router-dom";
import './login.css'
import '../fonts.css'

const generateStyleSheet = ({ imagesCount, duration, transition }) => {
  const t = imagesCount * (duration + transition)
  const p1 = Math.round(transition / t * 100)
  const p2 = Math.round((duration + transition) / t * 100)
  const p3 = Math.round(p2 * 1.1)

  const vendorBackfaceVisibility = vendor.supportedProperty('backface-visibility')
  const vendorAnimation = vendor.supportedProperty('animation')
  const vendorKeyframes = vendor.supportedKeyframes('@keyframes')

  let animation = ''
  let keyframes = ''
  if (vendorAnimation && vendorBackfaceVisibility && vendorKeyframes) {
    animation = `${vendorBackfaceVisibility}: hidden;
        ${vendorAnimation}: imageAnimation ${t}s linear infinite -0.5s;`

    keyframes = `${vendorKeyframes} imageAnimation {
          0% {
            opacity: 0;
            animation-timing-function: ease-in;
          }
          ${p1}% {
            opacity: 1;
            animation-timing-function: ease-out;
          }
          ${p2}% {
            opacity: 1;
          }
          ${p3}% {
            opacity: 0
          }
          100% {
            opacity: 0
          }
        }`
  }

  return `#ReactBackgroundSlider > figure {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        color: transparent;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0;
        z-index: -1000;
        margin: 0;
        ${animation}
      }
      ${keyframes}
    `
}

const injectCss = (css, meta) => {
  const head = document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  style.setAttribute('type', 'text/css')
  style.setAttribute('data-meta', meta)
  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
  head.appendChild(style)
}


const NAME = 'ReactBackgroundSlider'

function BackgroundSlider ({
  images,
  duration,
  transition
}) {
  React.useEffect(() => {
    injectCss(
      generateStyleSheet({
        imagesCount: images.length,
        duration,
        transition
      }),
      NAME
    )
  })

  return (
    <div id={NAME}>
      {images.map((img, key) =>
        <figure key={key}
          style={{
            backgroundImage: `url(${img})`,
            animationDelay: `${(duration + transition) * key}s`
          }}
        />
      )}
    </div>
  )
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link to="/">
        Prabhu Mein Tera
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '50px 30px 50px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    opacity: '95%',
    borderRadius: '5%',
    boxShadow: '0px 0px 34px -9px rgba(0,0,0,0.6)'
  },
  avatar: {
    backgroundColor: '#555',
  },
  form: {
    width: '90%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
  },
  error: {
    color: 'red'
  }
}));

function Login(props) {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [signinError, setsigninError] = useState("");

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if(name==='username')
      setusername(value);
    if(name==='password')
      setpassword(value);
  };

  const handleFormSubmit = event => {
    event.preventDefault();
    sessionStorage.setItem("876789", username);
    sessionStorage.setItem("453465", password);
    if (username && password && sessionStorage.getItem("876789") === "admin@lpdf.in" && sessionStorage.getItem("453465") === "u233773154_lpdf") {
      window.location.reload();
    }
    else {
      setsigninError("Either username or password is incorrect...");
    }
  };

  const classes = useStyles();

  if (sessionStorage.getItem("876789") === "admin@lpdf.in" && sessionStorage.getItem("453465") === "u233773154_lpdf") {
    return <Redirect to="/850274/Admin" />;
  }
  else return (
    <div className="login-wrapper" >
      {/* <img 
        className="login-wrapper-img" 
        src={require('../Images/1_md.jpg')}
        srcSet={`
          ${require('../Images/1_sm.jpg')} 500w, 
          ${require('../Images/1_md.jpg')} 1000w,
          ${require('../Images/1_lg.jpg')} 1500w
        `}
        alt=""
      /> */}
      <BackgroundSlider
        images={["https://lpdf.in/images/ekadashi/eleven-benefits_darkorange.jpg", "https://lpdf.in/images/anandutsav/bg1.jpg", "https://lpdf.in/images/pmt/collage.jpg"]}
        duration={4}
        transition={2}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} >
            <LockOutlinedIcon />
          </Avatar>
          <h1 className="login-header">LPDF</h1>
          <p className="login-subheader">Welcome to Admin App !</p>
          <p className={classes.error}>{signinError}</p>
          <form className={classes.form} onSubmit={() => {}} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  color="secondary"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="username"
                  autoComplete="email"
                  value={username}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  color="secondary"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleFormSubmit}
            >
              Log In
            </Button>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

export default Login;

import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './3gproject.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import { SliderLeftLong } from '../Components2/SliderLeft.js'
import '../fonts.css'
import {
	Link
} from "react-router-dom";


function ThreeGproject(props) {
	const { heading, subheading } = useContext(DataContext);

	return(
		<div>
			<Header 
				headerClass="all3gHeader"
				pageTitle={heading('3g_header')}
				pageSubTitle={subheading('3g_header')} 
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>

			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeftLong 
					img1="https://lpdf.in/images/3G/canva1.jpg"
					img2="https://lpdf.in/images/3G/canva2.jpg"
					img3="https://lpdf.in/images/3G/canva3.jpg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('3g_c1_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato pt-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('3g_c1_p1')}}></p>
						<p className="Bodytext-1regular-16ptcenterdark-lato pb-md-0" dangerouslySetInnerHTML={{__html: subheading('3g_c1_p2')}}></p>
						<div className="slider-long-extra-text">
					    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('3g_c1_p3')}}></p>
						</div>
					</aside>
		    	</div>
		    </div>
		    <div className="slider-long-extra-text-display-medium-sc">
		    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('3g_c1_p3')}}></p>
			</div>

			<div className="three-g three-g-one">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: 'white'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: 'white'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: 'white'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className="main-card">
					<div className="main-card-img main-card-img-gau"></div>
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('3g_part1')}</h2>
					<p className="text-justify Bodytext-1regular-16ptcenterdark-lato" dangerouslySetInnerHTML={{__html: subheading('3g_part1')}}></p>
					<Link to="/3G-Project/Gaay" className="card_custom_button">
				        Read More
				    </Link>  
				</div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{fill: "#fff"}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{fill: "#fff"}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{fill: "#fff"}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>

			<div className="three-g three-g-two">
				<div className="main-card main-card-mirror">
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('3g_part2')}</h2>
					<p className="text-justify Bodytext-1regular-16ptcenterdark-lato" dangerouslySetInnerHTML={{__html: subheading('3g_part2')}}></p>
					<Link to="/3G-Project/Ganga" className="card_custom_button">
				        Read More
				    </Link>  
					<div className="main-card-img main-card-img-ganga"></div>					
				</div>
			</div>

			<div className="three-g three-g-three">
				<svg className="wavy-divider" style={{transform: 'scale(-1,-1)'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path style={{opacity: '0.15', fill: '#fff'}} d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path style={{opacity: '0.3', fill: '#fff'}} d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path style={{opacity: '1', fill: '#fff'}} d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
				<div className="main-card">
					<div className="main-card-img main-card-img-gita"></div>
					<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('3g_part3')}</h2>
					<p className="text-justify Bodytext-1regular-16ptcenterdark-lato" dangerouslySetInnerHTML={{__html: subheading('3g_part3')}}></p>
					<Link to="/3G-Project/ShriGitaPrerna" className="card_custom_button">
				        Read More
				    </Link>  
				</div>
				<svg style={{marginBottom: "-5px"}} className="wavy-divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" preserveAspectRatio="none" width="100%" height="100">					
					<path d="M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"></path>
					<path d="M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"></path>
					<path d="M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"></path>
				</svg>
			</div>

		    <Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>

		</div>
	)
}

export default ThreeGproject;
import React, { useContext } from 'react'
import { DataContext } from '../Context/DataContext'
import './ekadashi.css'
import './3gproject.css'
import './ayushmanbhav.css'
import Header from '../Components/Header.js'
import Footer from '../Components/Footer.js'
import { SliderLeftLong } from '../Components2/SliderLeft.js'
import '../fonts.css'

function Ganga(props) {
	const { heading, subheading, paragraph, image } = useContext(DataContext);

	return(
		<div>
			<Header 
				headerClass="allGangaHeader"
				pageTitle={heading('ganga_header')} 
				pageSubTitle={subheading('ganga_header')} 
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
				curr = "3"
				buttonto="Explore More"
				amountToScroll={700}
			/>

			<div className="content-ekadashi-left-slider-wrapper content-ekadashi-left-slider-wrapper-display py-5">
				<SliderLeftLong 
					img1="https://lpdf.in/images/3G/canva3.jpg"
					img2="https://lpdf.in/images/3G/canva8.jpg"
					img3="https://lpdf.in/images/3G/canva9.jpg"
				/>
				<div className="greybox-div-ekadashi greybox-div-left-carousel-ekadashi px-4 pl-xl-5">
					<aside>
						<h2 className="Subheading-1bold-36ptleftdark-lato">{heading('ganga_c1_p1')}</h2>
						<p className="Bodytext-1regular-16ptcenterdark-lato pt-4 pb-md-0" dangerouslySetInnerHTML={{__html: subheading('ganga_c1_p1')}}></p>
						<p className="Bodytext-1regular-16ptcenterdark-lato pb-md-0" dangerouslySetInnerHTML={{__html: subheading('ganga_c1_p2')}}></p>
						<div className="slider-long-extra-text">
					    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('ganga_c1_p3')}}></p>
						</div>
					</aside>
		    	</div>
		    </div>
		    <div className="slider-long-extra-text-display-medium-sc">
		    	<p className="Bodytext-1regular-16ptcenterdark-lato pb-4 pb-md-0 text-justify" dangerouslySetInnerHTML={{__html: subheading('ganga_c1_p3')}}></p>
			</div>

			<div className="main-video-container my-5">
				<h2 className="Subheading-1bold-36ptcenterdark-lato">{heading('ganga_main_vdo')}</h2>
				<hr style={{backgroundColor: 'black', width: '150px', height: '1px'}}/>
				<p className="Bodytext-1regular-16ptcenterdark-lato text-justify text-md-center my-5">{subheading('ganga_main_vdo')}</p>
				<div className="iframe-container">
	                <iframe src={paragraph('ganga_main_vdo')} title="ganga_main_vdo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	            </div>
	        </div>

			<Footer
				svgPath2 = "M -286 255 s 214 -103 338 -129 s 203 29 384 101 c 145.57 57.91 178.7 50.79 272 0 c 79 -43 301 -224 385 -63 c 53 101.63 -62 129 -62 129 l -107 84 l -1212 12 z"
				svgPath3 = "M -12 201 s 70 83 194 57 s 160.29 -36.77 274 6 c 109 41 184.82 24.36 265 -15 c 55 -27 116.5 -57.69 214 4 c 49 31 95 26 95 26 l -6 151 l -1036 10 z"
				svgPath1 = "M -302 55 s 235.27 208.25 352 159 c 128 -54 233 -98 303 -73 c 92.68 33.1 181.28 115.19 235 108 c 104.9 -14 176.52 -173.06 267 -118 c 85.61 52.09 145 123 145 123 v 74 l -1306 10 z"
			/>
		</div>
	)
}

export default Ganga;
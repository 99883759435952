import React from 'react'
import './projects.css'
import './style.css'
import classNames from 'classnames';
import CardA from './CardA.js'
import '../fonts.css'

function Projects(props) {
	return(
		<div className="details-all">
			<div className={classNames("project-types-card-wrapper")}>
				<div className="details-small-screen-wrapper">
					<div className="details-left-sm">
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/2.jpg"
								name={props.title('projects_1')}
								linkto="/AscentOfLife"
								content={props.text('projects_1')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/1.jpg"
								name={props.title('projects_2')}
								linkto="3G-Project"
								content={props.text('projects_2')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/7.jpg"
								name={props.title('projects_3')}
								linkto="AnandUtsav"
								content={props.text('projects_3')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/3.jpg"
								name={props.title('projects_4')}
								linkto="/AyushmanBhav"
								content={props.text('projects_4')}
							/>
						</div>
					</div>
					<div className="details-right-sm">
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/5.jpg"
								name={props.title('projects_5')}
								linkto="/WelfareActivities"
								content={props.text('projects_5')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/8.jpg"
								name={props.title('projects_6')}
								linkto="/WomenEmpowerment"
								content={props.text('projects_6')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/6.jpg"
								name={props.title('projects_7')}
								linkto="/DivyaShishuRatna"
								content={props.text('projects_7')}
							/>
						</div>
						<div className="details-small-screen">
							<CardA
								url="https://lpdf.in/images/projects/4.jpg"
								name={props.title('projects_8')}
								linkto="/BodyServicingDay"
								content={props.text('projects_8')}
							/>
						</div>
					</div>
				</div>

				<div className="flower-projects-mid-screen">
					<img src={require("../images/projects/flower-allo-projects-alt.png")} height='100%' width='100%' alt="Projects"/>
				</div>

				<div className="details-large-screen-wrapper">
					<div className="details-left">

						<div className="details-left-left">
							<CardA
								url="https://lpdf.in/images/projects/2.jpg"
								name={props.title('projects_1')}
								linkto="/AscentOfLife"
								content={props.text('projects_1')}
							/>
						</div>

						<CardA
								url="https://lpdf.in/images/projects/1.jpg"
								name={props.title('projects_2')}
								linkto="3G-Project"
								content={props.text('projects_2')}
						/>

						<div className="details-left-left">
							<CardA
								url="https://lpdf.in/images/projects/7.jpg"
								name={props.title('projects_3')}
								linkto="AnandUtsav"
								content={props.text('projects_3')}
							/>
						</div>

					</div>

					<div className="details-center">
						<div className="details-top mt-n5 mt-lg-0">
								<CardA
									url="https://lpdf.in/images/projects/5.jpg"
									name={props.title('projects_5')}
									linkto="/WelfareActivities"
									content={props.text('projects_5')}
								/>
						</div>

						<div className="flower-projects">
							<img src={require("../images/projects/flower-allo-projects-alt.png")} height='100%' width='100%'/>
						</div>

						<div className="details-bottom my-n5 my-lg-0">
							<CardA
								url="https://lpdf.in/images/projects/3.jpg"
								name={props.title('projects_4')}
								linkto="/AyushmanBhav"
								content={props.text('projects_4')}
							/>
						</div>
					</div>

					<div className="details-right">

						<div className="details-right-right">
							<CardA
								url="https://lpdf.in/images/projects/8.jpg"
								name={props.title('projects_6')}
								linkto="/WomenEmpowerment"
								content={props.text('projects_6')}
							/>
						</div>

						<CardA
							url="https://lpdf.in/images/projects/6.jpg"
							name={props.title('projects_7')}
							linkto="/DivyaShishuRatna"
							content={props.text('projects_7')}
						/>

						<div className="details-right-right">
							<CardA
								url="https://lpdf.in/images/projects/4.jpg"
								name={props.title('projects_8')}
								linkto="/BodyServicingDay"
								content={props.text('projects_8')}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Projects;